import React, { useState, useEffect } from "react";
import '../DPCLayout.css'
import { useJsApiLoader } from "@react-google-maps/api";
import { MAP_KEY } from "../../config";
import RouteMap from "../../Components/RouteMap";

const DriverAllocationAddress = ({ source, locations, startTime, maxSegmentDuration }) => {
  const [segments, setSegments] = useState([]);
  const [segmentData, setSegmentData] = useState(null);
  useEffect(() => {
    if (source !== null && locations.length > 0) {
      calculateRoutes(source, locations);
    }
    // eslint-disable-next-line
  }, [source, locations]);
  const { isLoaded } = useJsApiLoader({ googleMapsApiKey: MAP_KEY });
  if (!isLoaded) return <div>Loading...</div>

  const calculateRoutes = (source, locations) => {
    const service = new window.google.maps.DistanceMatrixService();

    const destinations = locations.length > 0 && locations.map((location) => {
      const lastLocation = location.length > 1 ? location[location.length - 1] : location[0];
      return {
        lat: parseFloat(lastLocation?.lat),
        lng: parseFloat(lastLocation?.lng),
        address: lastLocation.full_address,
      };
    });

    const waypoints = locations.map((location) => {
      if (location.length <= 1) {
        return [];
      }
      return location.slice(0, -1).map((waypoint) => ({
        lat: parseFloat(waypoint.lat),
        lng: parseFloat(waypoint.lng),
        address: waypoint.full_address,
      }));
    });

    service.getDistanceMatrix(
      {
        origins: [new window.google.maps.LatLng(parseFloat(source?.lat), parseFloat(source?.lng))],
        destinations: [...destinations.flat(), ...waypoints.flat()],
        travelMode: window.google.maps.TravelMode.DRIVING,
      },
      (response, status) => {
        if (status === window.google.maps.DistanceMatrixStatus.OK) {
          const segmentArray = [];
          const hourThreshold = maxSegmentDuration;
          let currentSegmentDuration = 0;

          let currentSegment = {
            source: {
              lat: parseFloat(source?.lat),
              lng: parseFloat(source?.lng),
              address: `${source.full_address}`,
            },
            waypoints: [],
          };

          destinations.forEach((dest, index) => {
            const element = response.rows[0].elements[index];

            if (!element || !element.duration) {
              console.warn(`Missing data for destination ${index}`, dest);
              return;
            }

            const durationInSeconds = element.duration.value;
            currentSegmentDuration += durationInSeconds;

            if (currentSegmentDuration > hourThreshold && segmentArray.length > 0) {
              segmentArray.push(currentSegment);
              currentSegment = {
                source: {
                  lat: parseFloat(source?.lat),
                  lng: parseFloat(source?.lng),
                  address: `${source.full_address}`,
                },
                waypoints: [],
              };
              currentSegmentDuration = durationInSeconds;
            }

            currentSegment.destination = {
              lat: dest.lat,
              lng: dest.lng,
              address: dest.address,
            };
            currentSegment.duration = element.duration.text;
            currentSegment.eta = formatETA(new Date(startTime.getTime() + (currentSegmentDuration * 1000)));

            let currentETA = new Date(startTime.getTime() + (currentSegmentDuration * 1000));

            if (waypoints[index]) {
              waypoints[index].forEach((waypoint, waypointIndex) => {
                const waypointElement = response.rows[0].elements[destinations.length + waypointIndex];

                if (!waypointElement || !waypointElement.duration) {
                  console.warn(`Missing data for waypoint ${waypointIndex} in destination ${index}`, waypoint);
                  return;
                }

                const waypointDurationInSeconds = waypointElement.duration.value;
                currentSegmentDuration += waypointDurationInSeconds;

                if (currentSegmentDuration > hourThreshold) {
                  segmentArray.push(currentSegment);
                  currentSegment = {
                    source: {
                      lat: parseFloat(source?.lat),
                      lng: parseFloat(source?.lng),
                      address: `${source.full_address}`,
                    },
                    waypoints: [],
                  };
                  currentSegmentDuration = durationInSeconds + waypointDurationInSeconds;
                }

                const waypointSegment = {
                  lat: waypoint.lat,
                  lng: waypoint.lng,
                  address: waypoint.address,
                  duration: waypointElement.duration.text,
                  eta: formatETA(new Date(currentETA.getTime() + (waypointDurationInSeconds * 1000))),
                };

                currentSegment.waypoints.push(waypointSegment);
                currentETA = new Date(currentETA.getTime() + (waypointDurationInSeconds * 1000));
              });
            }
          });

          if (currentSegment.waypoints.length > 0 || currentSegment.destination) {
            segmentArray.push(currentSegment);
          }

          setSegments(segmentArray);
        } else {
          console.error("Error fetching distance matrix", status);
        }
      }
    );
  };

  const formatETA = (date) => {
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', second: '2-digit' });
  };

  const handleSegmentAction = (segment) => {
    setSegmentData(segment);
    const section = document.querySelector('#route-info');
    if (section) {
      section.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  return (
    <div className="row">
      <div className="col-md-12" style={{ overflow: 'auto' }}>
        <h5>Driver Route Information</h5>
        <div className="table-container">
          <table>
            <thead>
              <tr>
                <th>Driver #</th>
                <th>From</th>
                <th>To</th>
                <th>DeliverPoints</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {segments.map((segment, index) => {
                // Check if destination exists, otherwise, take the last waypoint and remove it from the list
                const destination = segment.destination?.address || segment.waypoints[segment.waypoints.length - 1]?.address || "N/A";
                const waypoints = segment.destination ? segment.waypoints : segment.waypoints.slice(0, -1);
                return (
                  <React.Fragment key={index}>
                    <tr key={index}>
                      <td>Driver {index + 1}</td>
                      <td>{segment.source.address}</td>
                      <td>{destination}</td>
                      <td>
                        <ul>
                          {waypoints.length > 0 ? (
                            waypoints.map((point, idx) => (
                              <li key={idx}>{point.address}</li>
                            ))
                          ) : (
                            <li>No Deliver Points</li>
                          )}
                        </ul>
                      </td>
                      <td>
                        <button className="action-button" onClick={() => handleSegmentAction(segment)}>Calculate Route</button>
                      </td>
                    </tr>
                  </React.Fragment>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      {
        // showMap &&
        <RouteMap segment={segmentData} />
      }
    </div>

  );
};

export default DriverAllocationAddress;
