import React, { useEffect, useState } from "react";
import { groupAddressesByRadius } from '../../Common/Functions/groupAddresses';
import { minutesToSeconds } from '../../Common/Functions/minutesToSeconds';
import api from "../../Common/Network/axiosConfig";
import DriverAllocationAddress from "./driverAllocations";
import '../DPCLayout.css'


export function CreateRoutes() {
  const [groupedAddressesList, setGroupedAddressesList] = useState([])
  const [duration, setDuration] = useState(60)
  const [maxSegmentDuration, setMaxSegmentDuration] = useState(0)
  const [radius, setRadius] = useState(10)
  const [addresses, setAddresses] = useState([])
  const [source, setSource] = useState(null)

  useEffect(() => {
    getSourceAddress()
    getAddressInfo()
  }, [])

  const getSourceAddress = async () => {
    try {
      const response = await api.get('/address/getSingleAddress');
      setSource(response.data)

    } catch (error) {
      console.error('Error fetching addresses:', error);
    }
  }

  const getAddressInfo = async () => {
    try {
      const response = await api.get('/address/getAllAddress', {
        params: {
          page: 1,
          limit: 500
        }
      });
      setAddresses(response.data.addresses);
    } catch (error) {
      console.error('Error fetching addresses:', error);
    }
  }


  useEffect(() => {
    let groupedAddresses = groupAddressesByRadius(addresses, radius);
    let groupedAddressesListData = groupedAddresses
      .filter(ire => ire.length <= 10) //max count 100
      .map(item => {
        return item.map(data => {
          return { lat: data.lat, lng: data.lng, full_address: data.full_address }
        })
      })
    setGroupedAddressesList(groupedAddressesListData)
    // eslint-disable-next-line
  }, [radius, duration, addresses])

  const startTimeOfParcel = new Date(); // Set your desired start time here

  useEffect(() => {
    if (duration === 0) {
      setMaxSegmentDuration(minutesToSeconds(60))
    } else {
      setMaxSegmentDuration(minutesToSeconds(duration))
    }
    // eslint-disable-next-line
  }, [duration])


  return (
    <div className='routeContainer'>
      {groupedAddressesList &&
        <div>
          <h5>Create Route</h5>
          <div className="directionContainer">
            <div className="labels">
              <label>Km in Radius</label>
              <input type="number" value={radius} className='form-control radius' onChange={(e) => setRadius(e.target.value)} />
            </div>
            <div className="labels">
              <label>Route Duration in Minutes</label>
              <input type="number" value={duration} className='form-control radius' onChange={(e) => setDuration(e.target.value)} />
            </div>
          </div>
          <DriverAllocationAddress
            source={source}
            locations={groupedAddressesList}
            startTime={startTimeOfParcel}
            maxSegmentDuration={maxSegmentDuration} />
        </div>
      }
    </div>
  )
}