import React from 'react';
import ListAddress from '../images/listaddress.png'
import AddAddress from '../images/address.png'
import CreateRoute from '../images/createroute.png'

import './DPCLayout.css';
import { useNavigate } from 'react-router-dom';

function DPCLayout() {
  const navigate = useNavigate();
  return (
    <div className="container">
      <div className="imgContainer" onClick={() => {
        navigate('/route')
      }}>
        <img
          src={CreateRoute}
          alt="Create Address"
          className="imageStyle"
        />
        <h4>Create Routes</h4>
      </div>
      <div className="imgContainer"
        onClick={() => {
          navigate('/addAddress')
        }}>
        <img
          src={AddAddress}
          alt="Add Address"
          className="imageStyle"
        />
        <h4>Add Address</h4>
      </div>
      <div className="imgContainer"
        onClick={() => {
          navigate('/listAddress')
        }}>
        <img
          src={ListAddress}
          alt="List Address"
          className="imageStyle"
        />
        <h4>List Address</h4>
      </div>
    </div>
  )
};

export default DPCLayout;
