import React, { useEffect, useState } from 'react';
import api from '../../Common/Network/axiosConfig';
import { useNavigate } from 'react-router-dom';

const AddressesList = () => {
    const navigate = useNavigate();
    const [addresses, setAddresses] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(100); // Default items per page
    const [totalAddresses, setTotalAddresses] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        const fetchAddresses = async () => {
            try {
                const response = await api.get('/address/getAllAddress', {
                    params: {
                        page: currentPage,
                        limit: itemsPerPage
                    }
                });
                setAddresses(response.data.addresses);
                setTotalAddresses(response.data.totalAddresses);
                setTotalPages(response.data.totalPages);
            } catch (error) {
                console.error('Error fetching addresses:', error);
            }
        };

        fetchAddresses();
    }, [currentPage, itemsPerPage]); // Fetch addresses whenever currentPage or itemsPerPage changes

    const handleDelete = async (address_id) => {
        // if (window.confirm('Are you sure you want to delete this address?')) {
            try {
                await api.delete(`/address/delete/${address_id}`);
                setAddresses(addresses.filter(address => address.address_id !== address_id));
                setTotalAddresses(totalAddresses - 1);
                alert('Address deleted successfully');
            } catch (error) {
                console.error('Error deleting address:', error);
            }
        // }
    };

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
        setCurrentPage(1);
    };

    const handleItemsPerPageChange = (e) => {
        setItemsPerPage(Number(e.target.value));
        setCurrentPage(1); // Reset to the first page when items per page changes
    };

    const filteredAddresses = addresses.filter(address =>
        address.address_name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    // Logic for pagination
    const maxVisiblePages = 10;
    const startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
    const endPage = Math.min(startPage + maxVisiblePages - 1, totalPages);

    // Ensure we have the correct start and end if we are near the start or end of the total pages
    const adjustedStartPage = Math.max(1, endPage - maxVisiblePages + 1);
    const adjustedEndPage = Math.min(totalPages, adjustedStartPage + maxVisiblePages - 1);

    return (
        <div className="m-2">
            <div className="d-flex gap-2">
              <button className='btn btn-sm btn-outline-info' type="button" onClick={() => {
                            navigate('/home')
                        }}>Get Route</button>
                <button className='btn btn-sm btn-outline-info' type="button" onClick={() => navigate('/')}>Home</button>
                <button className='btn btn-sm btn-outline-info' type="button" onClick={() => navigate('/addAddress')}>Add Address</button>
                <button className='btn btn-sm btn-outline-info' type="button" onClick={() => navigate('/createRoutes')}>Create Routes</button>
            </div>
            <h2 className="mb-4">Address List</h2>
            
            {/* Items per page dropdown */}
            <div className="mb-3">
                <label htmlFor="itemsPerPage" className="me-2">Items per page:</label>
                <select id="itemsPerPage" value={itemsPerPage} onChange={handleItemsPerPageChange} className="form-select" style={{ width: 'auto', display: 'inline-block' }}>
                    {[100,5, 10, 15, 20, 25].map(num => (
                        <option key={num} value={num}>{num}</option>
                    ))}
                </select>
            </div>

            <input
                type="text"
                placeholder="Search by address name..."
                value={searchTerm}
                onChange={handleSearch}
                className="form-control mb-3"
            />

            <table className="table table-bordered">
                <thead className="thead-dark">
                    <tr>
                        <th>Address Name</th>
                        <th>Full Address</th>
                        <th>Address Type</th>
                        <th>Latitude</th>
                        <th>Longitude</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredAddresses.length > 0 ? (
                        filteredAddresses.map((address) => (
                            <tr key={address.address_id}>
                                <td>{address.address_name}</td>
                                <td>{address.full_address}</td>
                                <td>{address.address_type === 'source' ? address.address_type : "destination" }</td>
                                <td>{address.lat}</td>
                                <td>{address.lng}</td>
                                <td>
                                    <button className="btn btn-danger btn-sm" onClick={() => handleDelete(address.address_id)}>
                                        Delete
                                    </button>
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="5" className="text-center">No addresses found</td>
                        </tr>
                    )}
                </tbody>
            </table>

            {/* Pagination Controls */}
            <nav>
                <ul className="pagination justify-content-center">
                    <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                        <button className="page-link" onClick={() => paginate(1)}>First</button>
                    </li>
                    {Array.from({ length: adjustedEndPage - adjustedStartPage + 1 }, (_, i) => (
                        <li key={adjustedStartPage + i} className={`page-item ${currentPage === adjustedStartPage + i ? 'active' : ''}`}>
                            <button onClick={() => paginate(adjustedStartPage + i)} className="page-link">
                                {adjustedStartPage + i}
                            </button>
                        </li>
                    ))}
                    <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                        <button className="page-link" onClick={() => paginate(totalPages)}>Last</button>
                    </li>
                </ul>
            </nav>
        </div>
    );
};

export default AddressesList;
