import React, { useState, useEffect, useRef } from 'react';
import { GoogleMap, DirectionsService, DirectionsRenderer, useJsApiLoader } from '@react-google-maps/api';
import { MAP_KEY } from '../../config';
import { formatDurationTime } from '../../Common/Functions/formatDurationTime';
import '../../DPCComponents/DPCLayout.css'

const haversineDistance = (lat1, lon1, lat2, lon2) => {
  const toRad = (value) => (value * Math.PI) / 180;
  const R = 6371;
  const dLat = toRad(lat2 - lat1);
  const dLon = toRad(lon2 - lon1);
  const a = Math.sin(dLat / 2) ** 2 + Math.cos(toRad(lat1)) * Math.cos(toRad(lat2)) * Math.sin(dLon / 2) ** 2;
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return R * c;
};

const addMinutes = (duration, minutesToAdd) => duration + minutesToAdd * 60;

const RouteMap = (props) => {

  const prevPropRef = useRef();

  const [currentLocation, setCurrentLocation] = useState(null);
  const [targetLocation, setTargetLocation] = useState(null);
  const [waypoints, setWaypoints] = useState([]);
  const [directionsResponse, setDirectionsResponse] = useState(null);
  const [arrivalTime, setArrivalTime] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [timeDifference, setTimeDifference] = useState({});
  const [routeSegments, setRouteSegments] = useState([]);
  const [showRoute, setShowRoute] = useState(false);
  const [directionsProcessed, setDirectionsProcessed] = useState(false);
  const [openMap, setOpenMap] = useState(false)

  const { isLoaded } = useJsApiLoader({ googleMapsApiKey: MAP_KEY });

  useEffect(() => {
    if (prevPropRef.current !== undefined && prevPropRef.current !== props) {

      let wayPointsArray = props.segment?.waypoints
      const destinationPointsArray = props.segment?.destination ? [props.segment.destination] : [];
      const deliveryPointsArray = [...destinationPointsArray, ...wayPointsArray];

      const newDPArray = deliveryPointsArray.sort((a, b) => {
        const latDiff = parseFloat(a.lat) - parseFloat(b.lat);
        if (latDiff !== 0) {
          return latDiff;
        }
        return parseFloat(a.lng) - parseFloat(b.lng);
      })

      // Reset directions response to clear the previous route
      setDirectionsResponse(null);
      setShowRoute(false);
      setDirectionsProcessed(false);

      // Set current and target locations and waypoints based on new segment data
      const source = props.segment?.source;
      const destination = newDPArray?.[newDPArray?.length - 1];

      // Determine waypoints if there are more than one delivery point
      const waypoints = newDPArray.length > 1
        ? newDPArray.slice(0, -1).map(item => ({
          location: { lat: item.lat, lng: item.lng },
          stopover: true
        }))
        : [];

      setCurrentLocation(source);
      setTargetLocation(destination);
      setWaypoints(waypoints);
      // Trigger the route display
      if (source && destination) {
        handleGetRoute(source, waypoints);
      }
    }
    prevPropRef.current = props;
    // eslint-disable-next-line
  }, [props.segment]);

  useEffect(() => {
    if (currentLocation && targetLocation && showRoute) {
      setDirectionsResponse(null);
      setArrivalTime(null);
      setStartTime(null);
      setTimeDifference({});
      setRouteSegments([]);
      setOpenMap(false)
    }
    // eslint-disable-next-line
  }, [currentLocation, targetLocation, waypoints, showRoute]);

  const handleDirectionsCallback = (response) => {
    if (response?.status === 'OK' && !directionsProcessed) {
      setDirectionsProcessed(true);
      setDirectionsResponse(response);
      const now = new Date();
      setStartTime(now);
      const totalDurationInSeconds = response.routes[0].legs.reduce((total, leg) => total + leg.duration.value, 0);
      let cumulativeDurationDestination = 0
      cumulativeDurationDestination += addMinutes(totalDurationInSeconds, (2 * response.routes[0].legs.length));
      const arrival = new Date(now.getTime() + cumulativeDurationDestination * 1000);
      setArrivalTime(arrival);

      const diffInMs = arrival - now;
      const minutes = Math.floor((diffInMs / 1000 / 60) % 60);
      const hours = Math.floor((diffInMs / 1000 / 60 / 60) % 24);
      const days = Math.floor(diffInMs / 1000 / 60 / 60 / 24);
      setTimeDifference({ days, hours, minutes });

      let cumulativeDuration = 0;
      const segments = response.routes[0].legs.map((leg, index) => {
        cumulativeDuration += addMinutes(leg.duration.value, 2);
        const eta = new Date(now.getTime() + cumulativeDuration * 1000);
        return {
          segment: `Delivery #${index + 1}`,
          startAddress: leg.start_address,
          endAddress: leg.end_address,
          duration: formatDurationTime(addMinutes(leg.duration.value, 2), true),
          eta: eta.toLocaleTimeString(),
        };
      });
      setRouteSegments(segments);
    } else if (response?.status === 'OVER_QUERY_LIMIT') {
      console.warn('Rate limit exceeded.');
    } else {
      // console.log('fetching directions', response);
    }
  };

  const sortWaypoints = (origin, waypoints) => waypoints.slice().sort((a, b) => {
    const distanceA = haversineDistance(origin.lat, origin.lng, a.location.lat, a.location.lng);
    const distanceB = haversineDistance(origin.lat, origin.lng, b.location.lat, b.location.lng);
    return distanceA - distanceB;
  });

  const handleGetRoute = (source, waypoints) => {
    const sortedWaypoints = sortWaypoints(source, waypoints);
    setWaypoints(sortedWaypoints);
    setShowRoute(true);
    setOpenMap(false)
  };

  const handleOpenMap = () => {
    setOpenMap(true)
    const section = document.querySelector('#map');
    if (section) {
      section.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }

  if (!isLoaded) return <div>Loading...</div>;


  return (
    <div className="m-1">
      <div className="row">
        <div id="route-info" className="col-md-12">
          {arrivalTime && startTime && (
            <div className="mt-3">
              <h5>Delivery Route Information</h5>
              <div className="table-container">
                <table>
                  <thead>
                    <tr>
                      <th>Delivery #</th>
                      <th>Starting Point</th>
                      <th>Destination</th>
                      <th>Duration</th>
                      <th>Starting Time</th>
                      <th>Ending Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    {routeSegments.map((segment, index) => (
                      <tr key={index}>
                        <td>{segment.segment}</td>
                        <td>{segment.startAddress} </td>
                        <td>{segment.endAddress}</td>
                        <td>{segment.duration}</td>
                        <td>
                          {index === 0
                            ? startTime.toLocaleTimeString()
                            : routeSegments[index - 1].eta}
                        </td>
                        <td>{segment.eta}</td>
                      </tr>
                    ))}
                    <tr>
                      <td>Total Time</td>
                      <td colSpan={2}></td>
                      <td>
                        {/* {timeDifference.days} days,  */}
                        {timeDifference.hours > 0 ? `${timeDifference.hours} hr, ` : null}
                        {timeDifference.minutes} mins</td>
                      <td>{startTime.toLocaleTimeString()}</td>
                      <td>{arrivalTime.toLocaleTimeString()}</td>
                    </tr>
                    <tr>
                      <button className="action-button"
                        style={{ float: 'right', margin: '1rem' }}
                        type="button" onClick={() => handleOpenMap()}>
                        Get Directions
                      </button>
                    </tr>
                  </tbody>
                </table>
              </div>
              {/* <button className='mt-2 mb-2 p-2 btn btn-sm btn-outline-info' type="button" onClick={() => handleOpenMap()}>Get Directions</button> */}
            </div>
          )}
          {currentLocation && targetLocation && showRoute && (
            <div id="map" className='mt-4'>
              <GoogleMap center={currentLocation} zoom={10} mapContainerStyle={{ width: '100%', height: '650px', display: openMap ? 'block' : 'none' }} >
                {currentLocation.lat !== 0 && targetLocation && showRoute && (
                  <DirectionsService
                    options={{
                      origin: currentLocation,
                      destination: targetLocation,
                      travelMode: 'DRIVING',
                      waypoints: waypoints,
                    }}
                    callback={handleDirectionsCallback}
                  />
                )}
                {directionsResponse && (
                  <DirectionsRenderer options={{ directions: directionsResponse }} />
                )}
              </GoogleMap>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default RouteMap;
